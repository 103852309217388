import React, { useState, useMemo, useRef } from "react";
import TinderCard from "react-tinder-card";
import removeDuplicates from "../removeDuplicates";
import db from "../data/videos";
function Advanced({ setLikedVids, likedVids }) {
  const [currentIndex, setCurrentIndex] = useState(db.length - 1);
  const [lastDirection, setLastDirection] = useState();
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canGoBack = currentIndex < db.length - 1;

  const canSwipe = currentIndex >= 0;

  // set last direction and decrease current index
  const swiped = (direction, id, index) => {
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    // console.log(direction);
    const prev = removeDuplicates(likedVids, "id");
    if (direction == "right") {
      const toAdd = db.find((item) => item.id == id);
      const final = removeDuplicates([...prev, toAdd], "id");
      setLikedVids(final);
    } else {
    }
  };
  const outOfFrame = (name, idx) => {
    // console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  // increase current index and show card
  const goBack = async (id) => {
    if (!canGoBack) return;
    console.log(
      likedVids.filter((item) => item.id != id),
      id,
      "asdasdsad"
    );
    const filtered = likedVids.filter((item) => item.id != id);
    const final = removeDuplicates([...filtered], "id");
    setLikedVids(final);
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  return (
    <div className="components-container">
      <link
        href="https://fonts.googleapis.com/css?family=Damion&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
        rel="stylesheet"
      />
      <h1>Popcorn AI Swiper</h1>
      <div className="cardContainer">
        {db.map((character, index) => (
          <TinderCard
            ref={childRefs[index]}
            className="swipe"
            key={character.id}
            onSwipe={(dir) => swiped(dir, character.id, index)}
            onCardLeftScreen={() => outOfFrame(character.id, index)}
          >
            <h3 className="name">{character.name}</h3>

            {/* <video
              width="100%"
              height="100%"
              controls
              className="card"
              autoPlay
              muted
              autoplay="autoplay"
              loop="loop"
              defaultMuted
              playsinline
              oncontextmenu="return false;"
              preload="auto"
              id="myVideo"
            >
              <source src={character.url} />
              Your browser does not support the video tag.
            </video> */}
            <div
              className="card"
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${character.url}"
                className="video"
             controls={true}
        />,
      `,
              }}
            ></div>
          </TinderCard>
        ))}
      </div>
      <div className="buttons">
        <button
          style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
          onClick={() => swipe("left")}
        >
          Dislike
        </button>
        <button
          style={{ backgroundColor: !canGoBack && "#c3c4d3" }}
          onClick={() => goBack(currentIndex + 1)}
        >
          Undo swipe!
        </button>
        <button
          style={{ backgroundColor: !canSwipe && "#c3c4d3" }}
          onClick={() => swipe("right")}
        >
          Like
        </button>
      </div>
      {lastDirection ? (
        <h2 key={lastDirection} className="infoText">
          You swiped {lastDirection}
        </h2>
      ) : (
        <h2 className="infoText">
          Swipe a card or press a button to get Restore Card button visible!
        </h2>
      )}
    </div>
  );
}

export default Advanced;
