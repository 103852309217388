import React from "react";
import "./index.css";
import close from "../Icons/close-icon.svg";
import removeDuplicates from "../removeDuplicates";
const LikedVideosComponent = ({
  data,
  likedVidsIsOpen,
  setLikedVidsIsOpen,
  setLikedVids,
}) => {
  const handleScroll = (e) => {
    e.stopPropagation(); // Prevent the scroll event from bubbling to the main page
    // Your custom scroll behavior for the fixed component
  };
  console.log(likedVidsIsOpen);
  function remove(id) {
    const filtered = data.filter((item) => item.id != id);
    const final = removeDuplicates([...filtered], "id");
    setLikedVids(final);
  }
  return (
    <div
      className={`likedVideosPageContainer ${
        likedVidsIsOpen ? "open" : "none"
      }`}
      style={likedVidsIsOpen ? { right: "-100%" } : { right: "0" }}
      onScroll={handleScroll}
    >
      <div className="closeIconContainer">
        <img src={close} onClick={() => setLikedVidsIsOpen(false)} />
      </div>
      <h1 style={{ fontSize: "50px" }}>Liked Videos</h1>
      <div className="vidsContainer">
        {data.length > 0 ? (
          data.map((item) => (
            <div className="likedCardContainer">
              <h3>{item.name}</h3>

              <div
                className="likedCard"
                dangerouslySetInnerHTML={{
                  __html: `
    <video
      loop
      muted
      src="${item.url}"
            className="video"
         controls={true}
    />,
  `,
                }}
              ></div>
              <button className="remove" onClick={() => remove(item.id)}>
                Remove
              </button>
            </div>
          ))
        ) : (
          <h2 style={{ fontWeight: "200", color: "red" }}>No Liked Videos</h2>
        )}
      </div>
    </div>
  );
};

export default LikedVideosComponent;
