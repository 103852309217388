import vid1 from "../img/Brother Sun.mp4";
import vid2 from "../img/InsideOut2.mp4";
import vid3 from "../img/Netflix Avatar.mp4";
import vid4 from "../img/Ozark.mp4";
import vid5 from "../img/TheFallGuy.mp4";
import vid6 from "../img/Way Down.mp4";

const DBTOEDIT = [
  {
    name: "Brother Sun",
    url: vid1,
  },
  {
    name: "Inside Out 2",
    url: vid2,
  },
  {
    name: "Netflix Avatar",
    url: vid3,
  },
  {
    name: "Ozark",
    url: vid4,
  },
  {
    name: "The Fall Guy",
    url: vid5,
  },
  {
    name: "Way Down",
    url: vid6,
  },
];
const db = DBTOEDIT.map((item, i) => ({ ...item, id: i }));

export default db;
