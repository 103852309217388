function removeDuplicates(arr, prop) {
  const uniqueMap = new Map();
  const uniqueArray = [];

  for (const item of arr) {
    const key = item[prop];

    if (!uniqueMap.has(key)) {
      uniqueMap.set(key, true);
      uniqueArray.push(item);
    }
  }
  return uniqueArray;
}
export default removeDuplicates;
