import React, { useState } from "react";
import close from "../Icons/close-icon.svg";
import emailjs from "emailjs-com";
import "./index.css";
const AIPage = ({ AIIsOpen, setAIIsOpen }) => {
  const [email, setEmail] = useState("");
  const handleScroll = (e) => {
    e.stopPropagation(); // Prevent the scroll event from bubbling to the main page
    // Your custom scroll behavior for the fixed component
  };
  var templateParams = {
    email,
  };
  console.log(AIIsOpen);
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_5ig5ods",
        "template_xdzkgaa",
        templateParams,
        "gdu8w-NpDYy4DsSvt"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    setEmail("");
  };

  return (
    <div
      className={`AIContainer ${AIIsOpen ? "closeAIPage" : "openAIPage"}`}
      style={AIIsOpen ? { right: "-100%" } : { right: "0" }}
      onScroll={handleScroll}
    >
      <div className="closeIconContainer">
        <img src={close} onClick={() => setAIIsOpen(true)} />
      </div>
      <h1 style={{ fontSize: "50px" }}>AI Feed</h1>
      <h3 style={{ color: "white", fontSize: "40px", fontWeight: "200" }}>
        Coming Soon
      </h3>
      <form className="AIForm">
        <input
          type="email"
          placeholder="Enter your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="button-63" onClick={handleSubmit}>
          Notify on Launch
        </button>
      </form>
    </div>
  );
};

export default AIPage;
