import React, { useState } from "react";
import Switch from "react-ios-switch";
import "./App.css";
import Advanced from "./examples/Advanced";
import Simple from "./examples/Simple";
import LikedVideosComponent from "./LikedVideosPage/LikedVideosComponent";
import AIPage from "./AIFORMPAGE/AIPage";
function App() {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [likedVidsIsOpen, setLikedVidsIsOpen] = useState(false);
  const [likedVids, setLikedVids] = useState([]);
  const [AIIsOpen, setAIIsOpen] = useState([]);
  return (
    <>
      <div className="app">
        <LikedVideosComponent
          data={likedVids}
          likedVidsIsOpen={likedVidsIsOpen}
          setLikedVidsIsOpen={setLikedVidsIsOpen}
          setLikedVids={setLikedVids}
        />
        <AIPage AIIsOpen={AIIsOpen} setAIIsOpen={setAIIsOpen} />
        <div className="liked-videos">
          <h2 onClick={() => setAIIsOpen((prev) => !prev)}>AI FEED</h2>
          <h2 onClick={() => setLikedVidsIsOpen((prev) => !prev)}>
            Liked Movies
          </h2>
        </div>

        {showAdvanced ? (
          <Advanced likedVids={likedVids} setLikedVids={setLikedVids} />
        ) : (
          <Simple likedVids={likedVids} setLikedVids={setLikedVids} />
        )}
        <div className="row">
          <p style={{ color: "#fff" }}>Show advanced example</p>{" "}
          <Switch checked={showAdvanced} onChange={setShowAdvanced} />
        </div>
      </div>
    </>
  );
}

export default App;
