import React, { useState } from "react";
import TinderCard from "react-tinder-card";
import db from "../data/videos";
import removeDuplicates from "../removeDuplicates";
function Simple({ setLikedVids, likedVids }) {
  const characters = [...db];
  const [lastDirection, setLastDirection] = useState();

  const swiped = (direction, id, index) => {
    setLastDirection(direction);
    const prev = removeDuplicates(likedVids, "id");
    if (direction == "right") {
      const toAdd = db.find((item) => item.id == id);
      const final = removeDuplicates([...prev, toAdd], "id");
      setLikedVids(final);
    } else {
    }
  };

  const outOfFrame = (name) => {
    console.log(name + " left the screen!");
  };
  return (
    <div className="components-container">
      <link
        href="https://fonts.googleapis.com/css?family=Damion&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
        rel="stylesheet"
      />
      <h1>Popcorn AI Swiper</h1>
      <div className="cardContainer">
        {characters.map((character) => (
          <TinderCard
            className="swipe"
            key={character.url}
            onSwipe={(dir) => swiped(dir, character.id)}
            onCardLeftScreen={() => outOfFrame(character.id)}
          >
            {/* <div style={{ backgroundImage: 'url(' + character.url + ')' }} className='card'>
              <h3>{character.name}</h3>
            </div> */}
            <h3 className="name">{character.name}</h3>

            <div
              className="card"
              dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          controls={true}
          src="${character.url}"
        />,
      `,
              }}
            ></div>
          </TinderCard>
        ))}
      </div>
      {lastDirection ? (
        <h2 className="infoText">You swiped {lastDirection}</h2>
      ) : (
        <h2 className="infoText" />
      )}
    </div>
  );
}

export default Simple;
